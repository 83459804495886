/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { musicGroup, musicComposition, graphWrapper } from "schema-comsat"
import { convertBreadcrumb } from "./schema-translate"

function SchemaMusicComposition({
  breadcrumbs,
  datePublished,
  title,
  pathname,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            canonicalUrl
            author
          }
        }
      }
    `
  )

  const rootUrl = site.siteMetadata.canonicalUrl

  const schema = graphWrapper([
    musicComposition({
      "id": rootUrl + pathname,
      composer: musicGroup({
        name: site.siteMetadata.title,
        "id": rootUrl,
      }),
      datePublished,
      mainEntityOfPage: rootUrl + pathname,
      name: title,
    }),
    convertBreadcrumb(rootUrl, breadcrumbs)
  ])

  return (
    <Helmet>
      <meta property="article:published_time" content={datePublished} />
      <meta property="og:type" content="article" />
      <meta name="twitter:card" content="summary" />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SchemaMusicComposition.propTypes = {
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  datePublished: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.any,
}

export default SchemaMusicComposition
