import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import TwoCol from "gatsby-theme-wirehead-bulma/src/twocol"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import SchemaMusicComposition from "../components/schema-musiccomposition"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import SocialMenu from "../components/socialmenu"
import SocialShare from "../components/socialshare"
import Breadcrumbs from "gatsby-theme-wirehead-bulma/src/breadcrumbs"
import TimeAgo from "timeago-react"
import loadable from "@loadable/component"

export default function MusicCompositionTemplate({
  data,
  location,
  pageContext,
}) {
  const { mdx, allSitePage } = data // data.markdownRemark holds your post data
  const { body } = mdx

  let shortcodes = {}

  if (typeof window === 'undefined') {
    let mockabc = () => {
      return (<br />)
    }
    shortcodes = { Abcjs: mockabc }
  } else {
    const Abcjs = loadable(() => import('react-abcjs'))
    shortcodes = { Abcjs }
  }

  var pages = data.allSitePage.edges.map(article => {
    return (
      <Link
        to={article.node.path}
        key={article.node.path}
        className="navbar-item"
      >
        {article.node.context.title}
      </Link>
    )
  })
  return (
    <Layout>
      <SEO
        title={pageContext.title}
        pathname={location.pathname}
        description={pageContext.excerpt}
      />
      <SchemaMusicComposition
        pathname={location.pathname}
        title={pageContext.title}
        datePublished={pageContext.date}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <Breadcrumbs
        depth={pageContext.depth}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <TwoCol
        left={
          <div className="content">
            <h1>{pageContext.title}</h1>
            <MDXProvider components={shortcodes}>
            <MDXRenderer pages={allSitePage}>
            {body}
            </MDXRenderer>
            </MDXProvider>
          </div>
        }
        right={
          <>
            <aside className="menu">
              {pages}
              <SocialMenu />
            </aside>
            <SocialShare link={location.pathname} />
            <div className="content">
              Posted: <TimeAgo datetime={pageContext.date} locale="en" />
            </div>
          </>
        }
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String, $path: String) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        type
        description
      }
      excerpt(pruneLength: 300)
    }
    allSitePage(filter: { context: { parent: { eq: $path } } }) {
      edges {
        node {
          context {
            id
            depth
            parent
            title
          }
          path
        }
      }
    }
  }
`
